<template>
  <div id="FBAds">
    <header>
      <top-title
      title="Facebook 廣告"
      subTitle="">
      </top-title>
    </header>
    <page-main-body
      title="Facebook 優勢"
      subTitle="Facebook advantage"
      paragraph1="數以百萬的大小商家都選擇 Facebook 的應用程式和服務，與各種裝置上的真實用戶建立聯繫。每個商家各不相同，因此 Facebook 設計了一系列應用程式和廣告服務，以協助您的商家成功達成理想目標。Facebook 有吸睛的廣告格式，能成功抓住用戶目光、鼓勵他們採取行動。這些廣告格式不但靈活彈性、能在各種裝置上呈現最佳效果，而且能助您達成業務目標。">
    </page-main-body>
    <div class="marketingPlan">
      <h2>行銷規劃</h2>
      <div class="item">
        <div class="text">
          <span>建立行銷活動</span>
          <p>選擇廣告受眾、版位和廣告創意，並深入瞭解可以利用哪些方法為客戶提升行銷活動的成效。</p>
        </div>
        <img src="../../../assets/image/service/mediaAds/FBAds/createActive.svg">
      </div>
      <div class="item">
        <img src="../../../assets/image/service/mediaAds/FBAds/targets.svg">
        <div class="text">
          <span>廣告受眾</span>
          <p>Facebook 強大的廣告受眾選擇工具有助您與較有可能對客戶業務感興趣的用戶建立聯繫。</p>
        </div>
      </div>
      <div class="item">
        <div class="text">
          <span>廣告創意</span>
          <p>利用鼓舞人心的廣告創意，為客戶展開故事行銷，創造理想的成果。</p>
        </div>
        <img src="../../../assets/image/service/mediaAds/FBAds/inovation.svg">
      </div>
    </div>
    <ad-form />
    <div class="which-story">
      <h2>您想以何種方式傳達品牌故事？</h2>
      <h3>Facebook 提供各式各樣的廣告格式，讓您以業務目標為廣告重點，同時兼顧廣告外觀，讓您吸引使用不同裝置與網路連線速度的廣告受眾。</h3>
      <div class="container">
        <div class="type">
          <img src="../../../assets/image/service/mediaAds/FBAds/storys/1.svg">
          <span>影片廣告</span>
        </div>
        <div class="type">
          <img src="../../../assets/image/service/mediaAds/FBAds/storys/2.svg">
          <span>圖像廣告</span>
        </div>
        <div class="type">
          <img src="../../../assets/image/service/mediaAds/FBAds/storys/3.svg">
          <span>精選輯廣告</span>
        </div>
        <div class="type">
          <img src="../../../assets/image/service/mediaAds/FBAds/storys/4.svg">
          <span>輪播</span>
        </div>
        <div class="type">
          <img src="../../../assets/image/service/mediaAds/FBAds/storys/5.svg">
          <span>輕影片</span>
        </div>
        <div class="type">
          <img src="../../../assets/image/service/mediaAds/FBAds/storys/6.svg">
          <span>全螢幕互動廣告</span>
        </div>
        <div class="type">
          <img src="../../../assets/image/service/mediaAds/FBAds/storys/7.svg">
          <span>名單型廣告</span>
        </div>
        <div class="type">
          <img src="../../../assets/image/service/mediaAds/FBAds/storys/8.svg">
          <span>優惠</span>
        </div>
        <div class="type">
          <img src="../../../assets/image/service/mediaAds/FBAds/storys/9.svg">
          <span>貼文互動</span>
        </div>
        <div class="type">
          <img src="../../../assets/image/service/mediaAds/FBAds/storys/10.svg">
          <span>活動回覆廣告</span>
        </div>
        <div class="type">
          <img src="../../../assets/image/service/mediaAds/FBAds/storys/11.svg">
          <span>粉絲專頁的讚</span>
        </div>
      </div>
    </div>
    <div class="trend">
      <h2>行銷動向</h2>
      <div class="inner">
        <trend v-if="trendTrigger"/>
        <h3>提高顧客忠誠度</h3> 
      </div>
    </div>
    <div class="FBAuthorize">
      <h2>Facebook 官方認証</h2>
      <img src="../../../assets/image/service/mediaAds/FBAds/FBAuthorize.jpg">
    </div>
    <div class="view-relate" @click="goto">
      觀看相關案例
    </div>
  </div>
</template>

<script>
import topTitle from "@/components/topTitle.vue";
import pageMainBody from "@/components/pageMainBody.vue";
import adForm from "@/components/adForm.vue";
import trend from "@/components/animations/trend.vue";

export default {
  name: "FBAds",
  components: {
    topTitle,
    pageMainBody,
    adForm,
    trend
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.$parent.inAdPage = true;
  },
  destroyed() {
    this.$parent.inAdPage = false;
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      trendTrigger: false
    };
  },
  methods: {
    handleScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      var hook = window.innerHeight;
      $(".item").each(function() {
        if (st > $(this).offset().top - hook * 0.8) {
          $(this).addClass("popout");
        } else {
          $(this).removeClass("popout");
        }
      });
      if (st > $(".which-story").offset().top - hook * 0.5) {
        $(".type").each(function(idx) {
          setTimeout(function() {
            if ($(".type")[idx]) {
              $(".type")[idx].classList.add("fadeIn");
            }
          }, 500 * idx);
        });
      }
      if (st > $(".trend").offset().top - hook * 0.8) {
        this.trendTrigger = true;
      }
    },
    goto() {
      this.$router.push({ name: 'CaseStudies', params: { caseType: 'facebook' }})
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

header {
  background-image: url("../../../assets/image/service/mediaAds/FBAds/topBanner.jpg");
}

#FBAds {
  display: flex;
  flex-direction: column;
  align-items: center;
  .marketingPlan {
    width: 90vw;
    max-width: 1200px;
    margin-bottom: 40px;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 3vh 0;
      width: 100%;
      height: 30vh;
      transform: scale(0);
      img {
        height: 100%;
        width: 16%;
      }
      .text {
        text-align: left;
        width: 70%;
        span {
          @include font(medium);
        }
        p {
          @include font(small);
        }
      }
      &.popout {
        transform: scale(1);
        animation: popout 0.5s;
        @keyframes popout {
          0% {
            transform: scale(0);
          }
          50% {
            transform: scale(1.2);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }
  }
  .which-story {
    position: relative;
    margin-top: 8vh;
    width: 90vw;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2,
    h3 {
      font-weight: 300;
    }
    .container {
      position: relative;
      margin-top: 8vh;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: wrap;
      .type {
        width: 190px;
        margin: 2vh 2vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translateY(12%);
        opacity: 0;
        transition: 0.5s;
        &.fadeIn {
          transform: translateY(0);
          opacity: 1;
        }
        img {
          width: 100%;
          height: 60%;
        }
        span {
          margin: 5% 0;
          @include font(small);
        }
        @media #{$phone} {
          width: 27%;
        }
      }
    }
  }
  .trend {
    margin: 4% 0;
    h3 {
      margin: 20px 0;
    }
    @media #{$tablet} {
      .inner {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
      }
      h3 {
        width: 5%;
        word-wrap: break-word;
        transform: translateX(-100%);
      }
    }
  }
  .FBAuthorize {
    width: 50%;
    h2 {
      margin: 4% 0;
    }
    img {
      width: 100%;
    }
  }
  .view-relate {
    cursor: pointer;
    width: 40vw;
    margin: 4% 0;
    @media #{$phone} {
      width: 75vw;
    }
    border: 1px solid #707070;
    padding: 1% 3%;
    transition: 0.3s;
    @include font(medium);
    font-weight: 300;
    &:hover {
      background-color: #707070;
      color: #fff;
    }
  }
}
</style>
