<template>
  <div id="trend">
    <div class="icon i1">
      <img src="@/assets/image/service/mediaAds/FBAds/trend/promote.svg">
      <span>打響名號</span>
    </div>
    <div class="icon i2">
      <img src="@/assets/image/service/mediaAds/FBAds/trend/makeNeeds.svg">
      <span>創造需求</span>
    </div>
    <div class="icon i3">
      <img src="@/assets/image/service/mediaAds/FBAds/trend/explore.svg">
      <span>開發客群</span>
    </div>
    <div class="icon i4">
      <img src="@/assets/image/service/mediaAds/FBAds/trend/deal.svg">
      <span>促成交易</span>
    </div>
    <font class="l1 ar"><div></div></font>
    <font class="l2 ar"><div></div></font>
    <font class="l3 ar"><div></div></font>
    <font class="l4"><div></div></font>
    <font class="l5 straight"><div class="straight"></div></font>
    <font class="l6"><div></div></font>
    <font class="l7 ar straight"><div class="straight"></div></font>
    <font class="l8 ar straight"><div class="straight"></div></font>
    <font class="l9 ar straight"><div class="straight"></div></font>
    <font class="l10 ar straight"><div class="straight"></div></font>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

// div {
//   border: 1px solid #73c;
// }

$iconSize: 12%;
$animationTime: 7s;
$straightLineLength: 38%;

#trend {
  position: relative;
  width: 80vw;
  height: 22vw;
  display: flex;
  .icon {
    position: absolute;
    width: $iconSize;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @for $i from 1 to 5 {
      &.i#{$i} {
        left: ($i - 1) * 25%;
        animation: popIn#{$i} $animationTime;
        @keyframes popIn#{$i} {
          0% {
            transform: scale(0);
          }
          #{(($i - 1) * 25%) / 2} {
            transform: scale(0);
          }
          #{(($i - 1) * 25% + 5) / 2} {
            transform: scale(1.1);
          }
          #{(($i - 1) * 25% + 10) / 2} {
            transform: scale(1);
          }
        }
      }
    }
    img {
      width: 100%;
      height: 70%;
    }
    span {
      @include font(small);
      transform: translateY(20%);
      white-space: nowrap;
    }
  }
  font {
    position: absolute;
    width: 20% - $iconSize;
    height: 1px;
    div {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #707070;
    }
    &.straight {
      width: 1px;
      height: $straightLineLength;
    }
    @for $i from 1 to 5 {
      // line 1 ~ 3
      &.l#{$i} {
        left: ($i - 1) * 25% + $iconSize + 2.5;
        top: 25%;
        div {
          animation: grow#{$i} $animationTime;
          @keyframes grow#{$i} {
            0% {
              width: 0;
            }
            #{(($i - 1) * 25% + $iconSize + 2.5) / 2} {
              width: 0;
            }
            #{(($i - 1) * 25% + $iconSize + 7) / 2} {
              width: 100%;
            }
          }
        }
      }
    }
    &.l4 {
      left: 75% + $iconSize;
      top: 25%;
      width: 13%;
      div {
        animation: grow4 $animationTime;
      }
    }
    &.l5 {
      top: 25%;
      right: 0;
      height: calc(75%);
      div {
        animation: grow5 $animationTime;
        @keyframes grow5 {
          0% {
            height: 0;
          }
          #{(3 * 25% + $iconSize + 7) / 2} {
            height: 0;
          }
          #{(3 * 25% + $iconSize + 20) / 2} {
            height: 100%;
          }
        }
      }
    }
    &.l6 {
      top: 100%;
      right: 0;
      width: 100% - $iconSize / 2;
      div {
        right: 0;
        animation: grow6 $animationTime;
        @keyframes grow6 {
          0% {
            width: 0;
          }
          #{(3 * 25% + $iconSize + 20) / 2} {
            width: 0;
          }
          #{(10 * 25% + $iconSize + 100 + 12) / 2} {
            width: 100%;
          }
        }
      }
    }
    @for $i from 7 to 11 {
      // line 7 ~ 10
      &.l#{$i} {
        left: (10 - $i) * 25% + $iconSize / 2;
        top: 100% - $straightLineLength;
        div {
          animation: grow#{$i} $animationTime;
          @keyframes grow#{$i} {
            0% {
              height: 0;
              top: 100%;
            }
            #{(($i - 7) * 25% + $iconSize + 100 + 5) / 2} {
              height: 0;
              top: 100%;
            }
            #{(($i - 7) * 25% + $iconSize + 100 + 12) / 2} {
              height: 100%;
              top: 0;
            }
          }
        }
      }
    }
    &.ar {
      div {
        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 20%;
          height: 1px;
          background-color: #707070;
          transform-origin: 100% 50%;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
        &.straight::before,
        &.straight::after {
          width: 1px;
          height: 20%;
          transform-origin: 50% 0%;
        }
      }
    }
  }
  @media #{$tablet} {
    width: 60vw;
    height: 120vh;
    .icon {
      width: 66%;
      height: 18%;
      flex-direction: row;
      @for $i from 1 to 5 {
        &.i#{$i} {
          left: 40%;
          top: ($i - 1) * 25%;
        }
      }
      span {
        width: 10%;
        height: 100%;
        white-space: normal;
        word-break: break-all;
      }
    }
    font {
      width: 1px;
      height: 10%;
      &.straight {
        width: 30%;
        height: 1px;
      }
      @for $i from 1 to 5 {
        // line 1 ~ 4
        &.l#{$i} {
          width: 1px;
          left: auto;
          top: ($i - 1) * 25% + 15;
          right: 25%;
          div {
            animation: grow#{$i} $animationTime;
            @keyframes grow#{$i} {
              0% {
                height: 0;
              }
              #{(($i - 1) * 25% + $iconSize + 2.5) / 2} {
                height: 0;
              }
              #{(($i - 1) * 25% + $iconSize + 7) / 2} {
                height: 100%;
              }
            }
          }
        }
      }
      &.l5 {
        top: auto;
        bottom: 0;
        right: 25%;
        height: 1px;
        width: 75%;
        div {
          animation: grow5 $animationTime;
          @keyframes grow5 {
            0% {
              width: 0;
              left: 100%;
            }
            #{(3 * 25% + $iconSize + 7) / 2} {
              width: 0;
              left: 100%;
            }
            #{(3 * 25% + $iconSize + 20) / 2} {
              width: 100%;
              left: 0;
            }
          }
        }
      }
      &.l6 {
        top: auto;
        bottom: 0;
        right: auto;
        left: 0;
        width: 1px;
        height: 90%;
        div {
          right: 0;
          bottom: 0;
          animation: grow6 $animationTime;
          @keyframes grow6 {
            0% {
              height: 0;
            }
            #{(3 * 25% + $iconSize + 20) / 2} {
              height: 0;
            }
            #{(10 * 25% + $iconSize + 100 + 12) / 2} {
              height: 90%;
            }
          }
        }
      }
      @for $i from 7 to 11 {
        // line 7 ~ 10
        &.l#{$i} {
          left: 0;
          top: (10 - $i) * 25% + 10;
          div {
            animation: grow#{$i} $animationTime;
            @keyframes grow#{$i} {
              0% {
                width: 0;
              }
              #{(($i - 7) * 25% + $iconSize + 100 + 5) / 2} {
                width: 0;
              }
              #{(($i - 7) * 25% + $iconSize + 100 + 12) / 2} {
                width: 100%;
              }
            }
          }
        }
      }
      &.ar {
        div {
          &::before,
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: auto;
            right: 0;
            bottom: 0;
            width: 1px;
            height: 20%;
            background-color: #707070;
            transform-origin: 0% 100%;
          }
          &:before {
            transform: rotate(45deg);
          }
          &:after {
            transform: rotate(-45deg);
          }
          &.straight::before,
          &.straight::after {
            width: 20%;
            height: 1px;
            transform-origin: 100% 0%;
          }
        }
      }
    }
  }
}
</style>
