<template>
  <div id="adFormPage" class="adFormPage">
    <div class="video">
      <video :src="`${baseUrl}static/video/adForm/${texts[index].type}.mp4`" autoplay muted></video>
    </div>
    <div class="text">
      <div class="title">
        <h2>{{texts[index].title}}</h2>
        <p>{{texts[index].subTitle}}</p>
      </div>
      <div class="content">
        <h3>{{texts[index].topic1}}</h3>
        <p>{{texts[index].content1}}</p>
        <h3>{{texts[index].topic2}}</h3>
        <p>{{texts[index].content2}}</p>
        <h3>{{texts[index].topic3}}</h3>
        <p>{{texts[index].content3}}</p>
        <h3>{{texts[index].topic4}}</h3>
        <p>{{texts[index].content4}}</p>
      </div>
    </div>
    <div class="cross" @click="close">
      <i class="far fa-times-circle"></i>
    </div>
  </div>
</template>

<script>
import loading from "@/components/loading.vue";

export default {
  components: {
    loading
  },
  props: {
    index: String | Number
  },
  data() {
    return {
      baseUrl: process.env.BASE_URL,
      loading: true,
      texts: [
        {
          textIdx: 0,
          type: "messenger",
          title: "Messenger",
          subTitle: "讓用戶輕鬆與您的公司展開對話。",
          topic1: "透過 Messenger 廣告觸及更多用戶",
          content1:
            "想要善加利用 Messenger 無遠弗屆的觸及範圍，最簡單的方式莫過於在 Messenger 刊登廣告。",
          topic2: "",
          content2:
            "Messenger 廣告的運作方式與其他 Facebook 平台上的廣告無異，我們會找出最有可能以最低成本帶動行銷活動成果的版位，並自動將廣告投遞到這些版位。更棒的是，您原本在 Facebook 和 Instagram 使用的廣告創意，也可以用在 Messenger 廣告中。",
          topic3: "",
          content3:
            "用戶將可以在 Messenger 應用程式看到廣告。當用戶點按廣告，他們便會前往您在廣告建立流程所選擇的目的地，例如您的網站、應用程式或是 Messenger 聊天畫面。"
        },
        {
          textIdx: 1,
          type: "photo",
          title: "相片廣告",
          subTitle: "呈現精美圖像，分享品牌訊息",
          topic1: "讓更多人注意到您的品牌",
          content1:
            "引人入勝的相片能在 Facebook 或 Instagram 上吸引用戶認識您的商家。",
          topic2: "提高產品的知名度",
          content2: "如果您想要推廣新的產品或服務，相片廣告就是您的最佳選擇。",
          topic3: "只要幾分鐘就能建立相片廣告",
          content3:
            "我們團隊可以為您的 Facebook 粉絲專頁發佈並加強推廣貼文，就能建立相片廣告。"
        },
        {
          textIdx: 2,
          type: "video",
          title: "影片廣告",
          subTitle: "透過視覺、聲音和影像傳達品牌故事",
          topic1: "吸引受眾目光",
          content1:
            "透過動態消息影片廣告，吸引用戶的短時間注意力。透過這些可迅速消化的短片加深用戶對您品牌的印象，同時藉此機會介紹新產品。",
          topic2: "與顧客建立聯繫",
          content2:
            "透過引人入勝的影片廣告，吸引用戶注意您的故事，讓他們在動態消息上停駐，開始關注您要傳遞的訊息。",
          topic3: "帶動顧客消費",
          content3:
            "將影片廣告搭配產品圖像和輪播廣告等不同手法，先引起用戶興趣，藉此鼓勵用戶深入瞭解品牌，進而提升銷售量。"
        },
        {
          textIdx: 3,
          type: "carousel",
          title: "輪播廣告",
          subTitle: "刊登一個廣告就能展示更多圖像或影片",
          topic1: "展示多個產品",
          content1:
            "在每張輪播圖卡分別顯示不同的產品並連結到不同的頁面，既讓顧客享有更多選擇，也鼓勵他們多多消費。",
          topic2: "突顯單一產品的多項功能",
          content2:
            "在每張輪播圖卡分別顯示單一產品的不同角度或細節，提供顧客更詳細的資訊。",
          topic3: "傳達動人故事",
          content3: "運用接龍式圖像或影片，講述扣人心弦的故事。",
          topic4: "說明程序",
          content4: "向顧客逐步解說商家的業務運作。"
        },
        {
          textIdx: 4,
          type: "clip",
          title: "輕影片廣告",
          subTitle:
            "製作輕量級影片廣告，無論在何種網路連線速度下都能與用戶建立聯繫",
          topic1: "吸睛力十足的廣告格式",
          content1: "輕影片能如同一般影片般播放並吸引注意。",
          topic2: "製作容易",
          content2:
            "輕影片廣告並不像某些影片廣告，製作起來既不費時費力，也不需要太多成本。",
          topic3: "任何環境都能運作",
          content3: "輕影片的載入速度快，在任何網路連線速度下都能流暢播放。"
        },
        {
          textIdx: 5,
          type: "selection",
          title: "精選集廣告",
          subTitle: "透過單一廣告展示您的產品、講述品牌故事",
          topic1: "利用精選集廣告引起購買意願",
          content1: "精選集廣告可在動態消息中抓住用戶目光，吸引用戶探索。",
          topic2: "透過即時體驗廣告提升購買意願",
          content2:
            "用戶一點擊精選集廣告，便會連結至即時體驗；您可運用此全螢幕體驗吸引用戶，使他們產生興趣、進一步增進購買意願。",
          topic3: "促使用戶化意願為行動，在網站或應用程式下手購買",
          content3:
            "即時體驗廣告會帶動人潮前往您的網站或應用程式，有助於將購買意願轉換為實際購買等有價值的流程下游動作。"
        }
      ]
    };
  },
  methods: {
    close() {
      this.$parent.pageOpen = false;
    }
  },
  mounted() {
    $(".video").css({
      height: $(".video").width() / 0.7 + "px"
    });
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/style.scss";

.adFormPage {
  position: absolute;
  width: 70%;
  background-color: #fff;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  overflow-y: auto;
  box-shadow: 0px 0px 20px 1px #707070;
  .video {
    width: 40%;
    height: 80%;
    transform: translateX(-20%);
    margin: 2% 0;
    #loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%)
    }
    video {
      width: 100%;
      height: 100%;
    }
  }
  .text {
    text-align: left;
    width: 35%;
    h2,
    h3,
    p {
      margin: 0;
    }
    .title {
      margin-bottom: 8%;
    }
    .content {
      p {
        margin-bottom: 2%;
      }
    }
  }
  .cross {
    cursor: pointer;
    position: absolute;
    @include font(large);
    display: flex;
    align-items: center;
    justify-content: center;
    right: 1%;
    top: 2%;
  }
  @media #{$tablet} {
    top: 2vh;
    width: 95vw;
    height: auto;
    flex-direction: column;
    .text {
      width: 80%;
    }
    .video {
      width: 70%;
      transform: translate(0);
    }
    .cross {
      top: 1%;
      right: 3%;
    }
  }
}
</style>
