<template>
  <div id="formCarousel">
    <h2>廣告格式 <hr></h2>
    <div class="prevBtn btn" @click="click('prev')" :class="{'open': pageOpen}">
      <i class="fas fa-angle-left"></i>
    </div>
    <div class="nextBtn btn" @click="click('next')" :class="{'open': pageOpen}">
      <i class="fas fa-angle-right"></i>
    </div>
    <transition name="popout">
      <ad-form-page v-if="pageOpen" :index="center" />
    </transition>
    <div class="slideStage" @mouseleave="mouseleave">
      <div class="slideContent"
        v-touch:swipe.left="swipeHandler" v-touch:swipe.right="swipeHandler"
        @mousedown="mousedown"  @mousemove="mousemove" @mouseup="mouseup">
        <div class="circle" @click="click(0)">
          <img src="../assets/image/service/mediaAds/FBAds/adForm/messenger.svg">
          <div class="title">Messenger</div>
          <div class="description">讓用戶輕鬆與您的公司展開對話</div>
        </div>
        <div class="circle center" @click="click(1)">
          <img src="../assets/image/service/mediaAds/FBAds/adForm/photo.svg">
          <div class="title">相片</div>
          <div class="description">呈現精美圖像，傳達品牌訊息</div>
        </div>
        <div class="circle" @click="click(2)">
          <img src="../assets/image/service/mediaAds/FBAds/adForm/video.svg">
          <div class="title">影片</div>
          <div class="description">透過視覺、聲音和影像傳達品牌故事</div>
        </div>
        <div class="circle" @click="click(3)">
          <img src="../assets/image/service/mediaAds/FBAds/adForm/carousel.svg">
          <div class="title">輪播</div>
          <div class="description">刊登一個廣告就能展示更多圖像或影片</div>
        </div>
        <div class="circle" @click="click(4)">
          <img src="../assets/image/service/mediaAds/FBAds/adForm/clip.svg">
          <div class="title">輕影片</div>
          <div class="description">製作輕量級影片廣告，無論在何種網路連線速度下都能與用戶建立聯繫</div>
        </div>
        <div class="circle" @click="click(5)">
          <img src="../assets/image/service/mediaAds/FBAds/adForm/selection.svg">
          <div class="title">精選集</div>
          <div class="description">透過單一廣告展示您的產品、講述品牌故事</div>
        </div>
      </div>
    </div>
    <div class="dots">
      <span :class="{'picked': center == 0}"  @click="click(0)"></span>
      <span :class="{'picked': center == 1}"  @click="click(1)"></span>
      <span :class="{'picked': center == 2}"  @click="click(2)"></span>
      <span :class="{'picked': center == 3}"  @click="click(3)"></span>
      <span :class="{'picked': center == 4}"  @click="click(4)"></span>
      <span :class="{'picked': center == 5}"  @click="click(5)"></span>
    </div>
  </div>
</template>

<script>
import adFormPage from "@/components/adFormPage.vue";

export default {
  components: {
    adFormPage
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.calibration();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      center: 1,
      mobile: window.innerWidth <= 768 ? true : false,
      pageOpen: false,
      draggable: false,
      dragging: false,
      dragOrigin: 0,
      translateX: 0
    };
  },
  methods: {
    calibration() {
      var vw = window.innerWidth;
      var pos;
      if (this.mobile) {
        pos = vw * (0.01 - 0.78 * this.center) + "px";
        $(".circle").css({
          width: vw * 0.6,
          height: vw * 0.6,
          "margin-left": vw * 0.09,
          "margin-right": vw * 0.09
        });
        $(".slideContent").css({
          transform: `translateX(${pos})`
        });
      } else {
        pos = vw * (0.27 - 0.26 * this.center) + "px";
        $(".circle").css({
          width: vw * 0.2,
          height: vw * 0.2,
          "margin-left": vw * 0.03,
          "margin-right": vw * 0.03
        });
        $(".slideContent").css({
          transform: `translateX(${pos})`
        });
      }
    },
    centerLocate() {
      var vm = this;
      var vw = window.innerWidth;
      $(".circle").each(function(idx) {
        if (
          $(this).offset().left > vw * 0.272 &&
          $(this).offset().left < vw * 0.528
        ) {
          $(this).addClass("center");
          vm.center = idx;
        } else {
          $(this).removeClass("center");
        }
      });
    },
    click(n) {
      if (!this.dragging) {
        var vw = window.innerWidth;
        if (n == "prev" || n == "next") {
          if (n == "prev") {
            this.center <= 0 ? (this.center = 5) : this.center--;
          } else {
            this.center >= 5 ? (this.center = 0) : this.center++;
          }
          var pos = this.mobile
            ? vw * (0.01 - 0.78 * this.center) + "px"
            : vw * (0.27 - 0.26 * this.center) + "px";
          $(".slideContent").css({
            transform: `translateX(${pos})`
          });
        } else {
          this.open();
          var pos = this.mobile
            ? vw * (0.01 - 0.78 * n) + "px"
            : vw * (0.27 - 0.26 * n) + "px";
          $(".slideContent").css({
            transform: `translateX(${pos})`
          });
          this.center = n;
        }
      }
    },
    open() {
      this.pageOpen = true;
    },
    handleResize() {
      this.mobile = window.innerWidth <= 768 ? true : false;
      this.calibration();
    },
    swipeHandler(direction) {
      direction == "left" ? this.click("next") : this.click("prev");
    },
    mousedown(e) {
      $(".slideContent").css({ transition: "0s" });
      this.dragOrigin = e.clientX;
      this.translateX = Number(
        $(".slideContent")
          .css("transform")
          .split(",")
          .slice(-2, -1)
      );
      this.draggable = true;
    },
    mouseup(e) {
      $(".slideContent").css({ transition: ".7s" });
      this.dragOrigin = e.clientX;
      this.translateX = Number(
        $(".slideContent")
          .css("transform")
          .split(",")
          .slice(-2, -1)
      );
      this.calibration();
      this.draggable = false;
    },
    mousemove(e) {
      if (this.draggable) {
        this.dragging = true;
        var displacement = e.clientX - this.dragOrigin;
        $(".slideContent").css({
          transform: `translateX(${this.translateX + displacement}px)`
        });
        this.centerLocate();
        setTimeout(() => {
          this.dragging = false;
        }, 0);
      }
    },
    mouseleave() {
      $(".slideContent").css({ transition: ".7s" });
      this.draggable = false;
      this.calibration();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/style.scss";

.popout-enter-active {
  animation: popIn 0.3s;
  @keyframes popIn {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}

.popout-leave-active {
  animation: popout 0.3s;
  @keyframes popout {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(0);
    }
  }
}

#formCarousel {
  position: relative;
  background-image: url("../assets/image/service/mediaAds/FBAds/adForm/BG.jpg");
  background-repeat: no-repeat;
  width: 100vw;
  height: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  h2 {
    color: #fff;
    margin-bottom: 0;
    @include font(medium);
    font-weight: 300;
    hr {
      width: 75%;
      height: 2px;
    }
  }
  .btn {
    cursor: pointer;
    position: absolute;
    $btnSize: 30px;
    width: $btnSize;
    height: $btnSize;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #fff;
    border: 3px solid #fff;
    border-radius: 100%;
    z-index: 3;
    @media #{$tablet} {
      &.open {
        color: #707070;
        border-color: #707070;
      }
    }
    &.prevBtn {
      top: calc(55% - #{$btnSize / 2});
      right: 90%;
      @media #{$tablet} {
        right: 86%;
      }
    }
    &.nextBtn {
      top: calc(55% - #{$btnSize / 2});
      left: 90%;
      @media #{$tablet} {
        left: 86%;
      }
    }
  }
  .slideStage {
    position: relative;
    display: flex;
    align-items: center;
    width: 80vw;
    height: 66%;
    overflow: hidden;
    .slideContent {
      display: flex;
      transition: 0.7s;
      .circle {
        position: relative;
        cursor: pointer;
        user-select: none;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 300;
        transition: 0.3s;
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: transparent;
        }
        img {
          height: 40%;
          margin: 2% 0;
        }
        .title {
          @include font(small);
        }
        .description {
          @include font(smaller);
          padding: 0 17%;
        }
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
  .dots {
    display: flex;
    span {
      cursor: pointer;
      $dotSize: 12px;
      width: $dotSize;
      height: $dotSize;
      background-color: #a1a1a1;
      border-radius: 100%;
      margin: 20px;
      transition: 0.3s;
      &.picked,
      &:hover {
        background-color: #fff;
      }
    }
  }
  @media #{$tablet} {
    height: 100vw;
  }
}
</style>
